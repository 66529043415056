<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
