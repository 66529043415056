<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<path
			d="M3.33301 4.5C3.33301 3.39543 4.22844 2.5 5.33301 2.5H14.6663C15.7709 2.5 16.6663 3.39543 16.6663 4.5V17.5L14.583 15.8333L12.4997 17.5L9.99967 15.8333L7.49967 17.5L5.41634 15.8333L3.33301 17.5V4.5Z"
			stroke="currentColor"
			stroke-miterlimit="10"
			stroke-linecap="square"
			stroke-linejoin="round"
		/>
		<path
			d="M6.66602 5.83398H13.3327"
			stroke="currentColor"
			stroke-miterlimit="10"
			stroke-linecap="round"
		/>
		<path
			d="M6.66602 8.33398H13.3327"
			stroke="currentColor"
			stroke-miterlimit="10"
			stroke-linecap="round"
		/>
	</svg>
</template>
