<template>
	<div class="w-full rounded border py-2 shadow sm:w-2/3 md:w-1/2">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'SectionCard'
};
</script>
