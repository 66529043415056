<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<path
			d="M4 3.5H16C16.8284 3.5 17.5 4.17157 17.5 5V7C17.5 7.82843 16.8284 8.5 16 8.5H4C3.17157 8.5 2.5 7.82843 2.5 7V5C2.5 4.17157 3.17157 3.5 4 3.5Z"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5 6H8"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<mask id="path-3-inside-1_26615_106286" fill="white">
			<path
				d="M15.25 6.5C15.6642 6.5 16 6.16421 16 5.75C16 5.33579 15.6642 5 15.25 5C14.8358 5 14.5 5.33579 14.5 5.75C14.5 6.16421 14.8358 6.5 15.25 6.5Z"
			/>
		</mask>
		<path
			d="M15.25 6.5C15.6642 6.5 16 6.16421 16 5.75C16 5.33579 15.6642 5 15.25 5C14.8358 5 14.5 5.33579 14.5 5.75C14.5 6.16421 14.8358 6.5 15.25 6.5Z"
			fill="currentColor"
		/>
		<path
			d="M14.5 5.75C14.5 5.33579 14.8358 5 15.25 5V8C16.4926 8 17.5 6.99264 17.5 5.75H14.5ZM15.25 5C15.6642 5 16 5.33579 16 5.75H13C13 6.99264 14.0074 8 15.25 8V5ZM16 5.75C16 6.16421 15.6642 6.5 15.25 6.5V3.5C14.0074 3.5 13 4.50736 13 5.75H16ZM15.25 6.5C14.8358 6.5 14.5 6.16421 14.5 5.75H17.5C17.5 4.50736 16.4926 3.5 15.25 3.5V6.5Z"
			fill="currentColor"
			mask="url(#path-3-inside-1_26615_106286)"
		/>
		<path
			d="M4 11.5H16C16.8284 11.5 17.5 12.1716 17.5 13V15C17.5 15.8284 16.8284 16.5 16 16.5H4C3.17157 16.5 2.5 15.8284 2.5 15V13C2.5 12.1716 3.17157 11.5 4 11.5Z"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5 14H8"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<mask id="path-7-inside-2_26615_106286" fill="white">
			<path
				d="M15.25 14.5C15.6642 14.5 16 14.1642 16 13.75C16 13.3358 15.6642 13 15.25 13C14.8358 13 14.5 13.3358 14.5 13.75C14.5 14.1642 14.8358 14.5 15.25 14.5Z"
			/>
		</mask>
		<path
			d="M15.25 14.5C15.6642 14.5 16 14.1642 16 13.75C16 13.3358 15.6642 13 15.25 13C14.8358 13 14.5 13.3358 14.5 13.75C14.5 14.1642 14.8358 14.5 15.25 14.5Z"
			fill="currentColor"
		/>
		<path
			d="M14.5 13.75C14.5 13.3358 14.8358 13 15.25 13V16C16.4926 16 17.5 14.9926 17.5 13.75H14.5ZM15.25 13C15.6642 13 16 13.3358 16 13.75H13C13 14.9926 14.0074 16 15.25 16V13ZM16 13.75C16 14.1642 15.6642 14.5 15.25 14.5V11.5C14.0074 11.5 13 12.5074 13 13.75H16ZM15.25 14.5C14.8358 14.5 14.5 14.1642 14.5 13.75H17.5C17.5 12.5074 16.4926 11.5 15.25 11.5V14.5Z"
			fill="currentColor"
			mask="url(#path-7-inside-2_26615_106286)"
		/>
	</svg>
</template>
