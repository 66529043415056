<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<path
			d="M3.02032 8.5625C3.2611 5.61306 5.61306 3.2611 8.5625 3.02032V8.0625C8.5625 8.33864 8.33864 8.5625 8.0625 8.5625H3.02032Z"
			stroke="currentColor"
		/>
		<path
			d="M8.5625 16.9797C5.61306 16.7389 3.2611 14.3869 3.02032 11.4375H8.0625C8.33864 11.4375 8.5625 11.6614 8.5625 11.9375V16.9797Z"
			stroke="currentColor"
		/>
		<path
			d="M11.4375 3.02032C14.3869 3.2611 16.7389 5.61306 16.9797 8.5625H11.9375C11.6614 8.5625 11.4375 8.33864 11.4375 8.0625V3.02032Z"
			stroke="currentColor"
		/>
		<path
			d="M11.4375 11.9375C11.4375 11.6614 11.6614 11.4375 11.9375 11.4375H16.9797C16.7389 14.3869 14.3869 16.7389 11.4375 16.9797V11.9375Z"
			stroke="currentColor"
		/>
	</svg>
</template>
