<template>
	<div
		class="grid h-4 w-4 shrink-0 place-items-center rounded-full border border-gray-500 bg-gray-500"
	>
		<svg
			width="10"
			height="8"
			viewBox="0 0 10 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.26562 3.86686L3.93229 6.53353L9.26562 1.2002"
				stroke="#FBFBF8"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</div>
</template>
